@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Bricolage Grotesque', sans-serif;
}


.custom-checkbox {
  @apply appearance-none h-[17px] w-[17px] border-2 border-white rounded-full;
  position: relative;
}

.custom-checkbox:checked::before {
  content: '';
  @apply bg-white rounded-full;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.custom-checkbox.error {
  @apply text-red-500 text-xs border-red-500;
}